import { reportCustomException } from '@infobip/portal-observability';

import { logDebug, logError } from '@/logger';
import { SharedStore } from '@/sharedStore';

import cdnCheckImage from './cdn-check.png';

const currentScriptSrc = (document.currentScript as HTMLScriptElement)?.src;
const oneDay = 24 * 60 * 60 * 1000;
const storageKey = 'cdn-proxy-service-worker';
const workerInstallationMeta = getWorkerInstallationMeta();
const serviceWorkerPath = '/serviceWorker.js';
const portalCdnProxyPath = '/portal-cdn-proxy';
const currentVersion = getCurrentVersion();

interface WorkerInstallationMeta {
    installedAt: number;
    version: string;
}

export async function main(): Promise<void> {
    try {
        if (!window.navigator || !window.navigator.serviceWorker) {
            return;
        }

        if (isCdnProxyDisabled()) {
            await uninstallServiceWorker();
            logDebug('Not installing service worker for CDN proxy because it is disabled.');
            return;
        }

        if (await isCdnAvailable()) {
            await uninstallServiceWorker();
            logDebug('Not installing service worker for CDN proxy because CDN is available.');
            return;
        }

        if (!isServiceWorkerExpired()) {
            logDebug('Not installing service worker for CDN proxy because it is not expired.');
            await configureServiceWorker();
            return;
        }
        await uninstallServiceWorker();
        const serviceWorker = await installServiceWorker();

        // to avoid redirect loop
        if (serviceWorker && !location.search.includes('cdn-proxy-installed')) {
            const newUrl = new URL(location.href);
            newUrl.searchParams.set('cdn-proxy-installed', '1');
            location.assign(newUrl);
        }
    } catch (error) {
        logErrorAndReport('General Error', error);
    }
}

function logErrorAndReport(message: string, error: unknown): void {
    logError(message, error);
    reportCustomException(`[cdn-proxy-service-worker] ${message}`, error);
}

function isCdnProxyDisabled(): boolean {
    return getFromMeta('cdn-proxy-service-worker-disabled') === 'true';
}

async function configureServiceWorker(enabled = !isCdnProxyDisabled()): Promise<void> {
    const sharedStore = await SharedStore.getInstance();
    await sharedStore.setConfig({
        enabled,
        cdnProxies: {
            [getCdnFromMeta()]: portalCdnProxyPath,
        },
        cdnProxyMappings: {
            '^([a-z0-9-]+)\\.cloudfront\\.net$': '/acf-proxy/$1',
        },
        cdnCheckImagePath: cdnCheckImage.split(portalCdnProxyPath)[1],
    });
}

async function installServiceWorker(): Promise<ServiceWorker> {
    await configureServiceWorker();
    const workerRegistration = await navigator.serviceWorker.register(
        `${getCdnUrlWithCurrentAppVersion()}${serviceWorkerPath}`,
        {
            // Root scope to make sure service worker is installed for all pages.
            // This script must return header with 'Service-Worker-Allowed' header set to '/' otherwise service worker will fail to install.
            // It must be configured on the CloudFront distribution.
            scope: '/',
        }
    );

    const installedServiceWorker = await waitUntilServiceWorkerIsReady(workerRegistration);
    serWorkerInstallationMeta()

    return installedServiceWorker;
}

async function waitUntilServiceWorkerIsReady(workerRegistration: ServiceWorkerRegistration): Promise<ServiceWorker> {
    return new Promise((resolve) => {
        if (workerRegistration.active) {
            resolve(workerRegistration.active);
        } else {
            const installingWorker = workerRegistration.installing;

            if (installingWorker) {
                installingWorker.addEventListener('statechange', () => {
                    if (installingWorker.state === 'activated') {
                        resolve(installingWorker);
                    }
                });
            }
        }
    });
}

async function getServiceWorkerRegistration(): Promise<ServiceWorkerRegistration | null> {
    try {
        const workersRegistrations = await navigator.serviceWorker.getRegistrations();
        for (const workerRegistration of workersRegistrations) {
            const workerScript = [
                workerRegistration.active,
                workerRegistration.installing,
                workerRegistration.waiting,
            ].find(Boolean)?.scriptURL;
            if (workerScript && workerScript.includes(serviceWorkerPath)) {
                return workerRegistration;
            }
        }
        return null;
    } catch (error) {
        logErrorAndReport('Error getting service worker', error);
        return null;
    }
}

async function uninstallServiceWorker() {
    clearWorkerInstallationMeta();
    try {
        const workerRegistration = await getServiceWorkerRegistration();
        if (workerRegistration) {
            logDebug('Unregistering service worker');
            await workerRegistration.unregister();
            await configureServiceWorker(false); // worker is not uninstalled immediately, so let's disable current one until it's uninstalled
        }
    } catch (error) {
        logErrorAndReport('Error uninstalling service worker', error);
    }
}

function isServiceWorkerExpired(): boolean {
    try {
        return (
            !workerInstallationMeta ||
            workerInstallationMeta.installedAt + oneDay < Date.now() ||
            workerInstallationMeta.version !== currentVersion
        );
    } catch {
        return true;
    }
}

function serWorkerInstallationMeta(): void {
    try {
        localStorage.setItem(
            storageKey,
            JSON.stringify({
                installedAt: Date.now(),
                version: currentVersion,
            })
        );
    } catch {
        return undefined;
    }
}

function getWorkerInstallationMeta(): WorkerInstallationMeta | null {
    try {
        const data = localStorage.getItem(storageKey);
        return data && JSON.parse(data);
    } catch {
        return null;
    }
}

function clearWorkerInstallationMeta(): void {
    try {
        localStorage.removeItem(storageKey);
    } catch {
        return undefined;
    }
}

function isCdnAvailable(): Promise<boolean> {
    return new Promise((resolve, reject) => {
        try {
            const img = new Image();
            let resolved = false;
            const timeout = setTimeout(() => resolve(false), 5000);
            const resolveWithResult = (result: boolean) => {
                if (resolved) {
                    return;
                }
                resolved = true;
                clearTimeout(timeout);
                resolve(result);
            };
            img.onload = () => resolveWithResult(true);
            img.onerror = () => resolveWithResult(false);
            // adding timestamp as query param as cache buster to avoid false positive result that CDN is available because of browser cache
            img.src = `${cdnCheckImage.replace(getCdnUrlWithCurrentAppVersion(), getCdnFromMetaWithVersion())}?t=${Date.now()}`;
        } catch (error) {
            reject(error);
        }
    });
}

function getCdnUrlWithCurrentAppVersion(): string {
    const appUrl = new URL(currentScriptSrc);
    if (currentVersion) {
        return appUrl.href.split(currentVersion)[0] + currentVersion;
    }

    return appUrl.href.substring(0, appUrl.href.lastIndexOf('/'));
}

/**
 * Get CDN URL from meta tag with version.
 * We cannot use the current script CDN URL, because current script is loaded from CDN proxy.
 * So this gives us origin CDN URL which we can use to check if CDN is available.
 */
function getCdnFromMetaWithVersion(): string {
    const version = currentVersion ?? '__latest__';
    const cdnBase = getCdnFromMeta();
    return `${cdnBase.startsWith('http') ? cdnBase : `//${cdnBase}`}/cdn-proxy-service-worker/${version}`;
}

function getCdnFromMeta(): string {
    const cdnBase = getFromMeta('cdn-domain');
    if (!cdnBase) {
        throw new Error('cdn-domain meta tag is missing');
    }

    return cdnBase;
}

function getCurrentVersion(): string | null {
    try {
        return new URL(currentScriptSrc).href.match(/\d+\.\d+\.\d+[^/]*/)?.[0] ?? null;
    } catch {
        return null;
    }
}

function getFromMeta(name: string): string | null {
    return document.querySelector(`meta[name="${name}"]`)?.getAttribute('content') ?? null;
}
