const isDebugEnabled = getIsDebugEnabled();
const prefix = '[CDN proxy service worker]';

export function logDebug(...args: any[]): void {
    if (isDebugEnabled) {
        console.debug(prefix, ...args);
    }
}
export function logError(...args: any[]): void {
    console.error(prefix, ...args);
}

function getIsDebugEnabled(): boolean {
    try {
        return localStorage.getItem('cdn-proxy-debug') === 'true';
    } catch {
        return false;
    }
}
