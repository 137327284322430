import { ServiceWorkerConfig } from '@/types';

const configObjectStoreKey = 'config';

export class SharedStore {
    private static instance: Promise<SharedStore>;

    constructor(private readonly db: IDBDatabase) {}

    static getInstance(): Promise<SharedStore> {
        if (!SharedStore.instance) {
            SharedStore.instance = SharedStore.createInstance();
        }
        return SharedStore.instance;
    }

    private static async createInstance(): Promise<SharedStore> {
        return new SharedStore(await createIndexDb());
    }

    async setConfig(config: ServiceWorkerConfig): Promise<void> {
        return new Promise((resolve, reject) => {
            const store = this.getConfigObjectStore('readwrite');
            const request = store.put(config, configObjectStoreKey);
            request.onsuccess = () => resolve();
            request.onerror = reject;
        });
    }

    async getConfig(): Promise<ServiceWorkerConfig> {
        return new Promise((resolve, reject) => {
            const store = this.getConfigObjectStore('readonly');
            const request = store.get(configObjectStoreKey);
            request.onsuccess = () => resolve(request.result);
            request.onerror = reject;
        });
    }

    private getConfigObjectStore(mode: IDBTransactionMode): IDBObjectStore {
        const transaction = this.db.transaction(configObjectStoreKey, mode);
        return transaction.objectStore(configObjectStoreKey);
    }
}

async function createIndexDb(): Promise<IDBDatabase> {
    const request = indexedDB.open('cdn-proxy-store', 1);

    return new Promise((resolve, reject) => {
        request.onerror = reject;
        request.onsuccess = () => resolve(request.result);
        request.onupgradeneeded = () => {
            const db = request.result;
            if (!db.objectStoreNames.contains(configObjectStoreKey)) {
                db.createObjectStore(configObjectStoreKey, { autoIncrement: false });
            }
        };
    });
}
